import React from 'react';
import {AppBar, Button, Grid, makeStyles, Paper, Toolbar, Typography} from '@material-ui/core';
import routes from './constants/routes';

const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: theme.spacing(2),
        backgroundColor: '#222222'
    },
    paper: {
        margin: 'auto',
        maxWidth: '750px',
        height: '100vh',
        backgroundColor: 'black',
        color: 'white',
        fontFamily: 'FenwickWoodtype',
    },
    title: {
        color: 'white',
        fontFamily: 'FenwickWoodtype',
        whiteSpace: 'nowrap',
    },
    link: {
        fontFamily: 'FenwickWoodtype',
        whiteSpace: 'nowrap',
        textDecoration: 'underline',
        fontWeight: 'bold',
    },
    toolbar: {
        backgroundColor: '#000000',
        fontFamily: 'FenwickWoodtype',
    }
}));

function Header(props) {
    const classes = useStyles();

    const handleClick = (route) => () => {
        props.setDisplayPath(route);
    }

    return (
        <React.Fragment>
            <AppBar position='sticky'>
                <Toolbar className={classes.toolbar}>
                    <Grid container justify='space-around'>
                        <Grid item>
                            <Typography variant='h4' className={classes.title}>
                                The Wedding of Alex and Cynara
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container>
                                <Grid item>
                                    <Button color='inherit' onClick={handleClick(routes.home)}>
                                        <Typography variant='h6' className={classes.link}>
                                            Home
                                        </Typography>
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button color='inherit' onClick={handleClick(routes.liveWeddingPreview)}>
                                        <Typography variant='h6' className={classes.link}>
                                            Watch
                                        </Typography>
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button color='inherit' onClick={handleClick(routes.reception)}>
                                        <Typography variant='h6' className={classes.link}>
                                            Reception
                                        </Typography>
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button color='inherit' onClick={handleClick(routes.registry)}>
                                        <Typography variant='h6' className={classes.link}>
                                            Registry
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <Paper className={classes.paper}>
                {props.children}
            </Paper>
        </React.Fragment>
    )
}

export default Header;