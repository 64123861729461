import React from 'react';
import Header from './Header';
import {makeStyles, Typography} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: 'FenwickWoodtype',
        marginBottom: '30px'
    },
    body: {
        lineHeight: '1.75',
        margin: '30px',
        fontFamily: 'FenwickWoodtype'
    },
}));

function LiveWeddingPreview(props) {
    const classes = useStyles();

    return (
        <Header setDisplayPath={props.setDisplayPath}>
            <Typography variant='h2' align='center' className={classes.title}>Watch the Wedding with us</Typography>
            <Typography variant='h4' paragraph={true} className={classes.body}>
                &emsp;We are planning a live premiere of our wedding video. We will have a time where everyone can join online and watch the wedding together.
            </Typography>
            <Typography variant='h4' paragraph={true} className={classes.body}>
                &emsp;This page will be updated with additional information as we put everything together. Check back later for the time and date as well as instructions on how to join the steam.
            </Typography>
            <Typography variant='h4' paragraph={true} className={classes.body}>
                &emsp;We look forward to sharing this special moment with all of you!
            </Typography>
        </Header>
    );
}

export default LiveWeddingPreview;