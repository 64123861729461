import React from 'react';
import Header from './Header';
import {Link, makeStyles, Typography} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: 'FenwickWoodtype',
        marginBottom: '30px'
    },
    body: {
        lineHeight: '1.75',
        margin: '30px',
        fontFamily: 'FenwickWoodtype',
    },
    link: {
        color: 'cornflowerblue'
    }
}));

function Registry(props) {
    const classes = useStyles();

    return (
        <Header setDisplayPath={props.setDisplayPath}>
            <Typography variant='h2' align='center' className={classes.title}>Registered at:</Typography>
            <Typography variant='h4' align='center' paragraph={true} className={classes.body}>
                <Link href='https://www.amazon.com/wedding/share/thedaigres' target='_blank' className={classes.link}>
                    Amazon
                </Link>
            </Typography>
            <Typography variant='h4' align='center' paragraph={true} className={classes.body}>
                <Link href='http://tgt.gifts/thedaigres' target='_blank' className={classes.link}>
                    Target
                </Link>
            </Typography>
            <Typography variant='h4' align='center' paragraph={true} className={classes.body}>
                <Link href='https://www.honeyfund.com/wedding/daigre-ellis-04-04-2021' target='_blank' className={classes.link}>
                    Honeyfund
                </Link>
            </Typography>
        </Header>
    );
}

export default Registry;