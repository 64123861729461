import './App.css';
import React, {useState} from 'react';
import Home from './Home';
import LiveWeddingPreview from './LiveWeddingPreview';
import Reception from './Reception';
import Registry from './Registry';
import routes from './constants/routes';
import {createMuiTheme, CssBaseline, MuiThemeProvider} from '@material-ui/core';

const theme = createMuiTheme({
    palette: {
        background: {
            default: '#000000'
        },
        text: {
            primary: '#ffffff',
            fontFamily: 'FenwickWoodtype'
        }
    }
});

const selectPage = (displayPath, setDisplayPath) => {
    switch (displayPath) {
        case routes.home:
            return <Home setDisplayPath={setDisplayPath}/>;
        case routes.liveWeddingPreview:
            return <LiveWeddingPreview setDisplayPath={setDisplayPath}/>;
        case routes.reception:
            return <Reception setDisplayPath={setDisplayPath}/>;
        case routes.registry:
            return <Registry setDisplayPath={setDisplayPath}/>;
        default:
            return <Home setDisplayPath={setDisplayPath}/>;
    }
}

function App() {
    const [displayPath, setDisplayPath] = useState(routes.home);
    return (
        <React.StrictMode>
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                    {selectPage(displayPath, setDisplayPath)}
            </MuiThemeProvider>
        </React.StrictMode>
    );
}

export default App;