import React from 'react';
import Header from './Header';
import {makeStyles, Typography} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: 'FenwickWoodtype',
        marginBottom: '30px'
    },
    body: {
        lineHeight: '1.75',
        margin: '30px',
        fontFamily: 'FenwickWoodtype'
    },
}));

function Home(props) {
    const classes = useStyles();

    return (
        <Header setDisplayPath={props.setDisplayPath}>
            <Typography variant='h2' align='center' className={classes.title}>The Wedding</Typography>
            <Typography variant='h4' paragraph={true} className={classes.body}>
                &emsp;After being together for 7 years and engaged for 2 we are finally getting married!
            </Typography>
            <Typography variant='h4' paragraph={true} className={classes.body}>
                &emsp;Due to the pandemic we are keeping this event very small. We still want you to celebrate with us, so we will be hosting a live premiere of our wedding video and a reception at a later date. Check the Watch and Reception pages for details.
            </Typography>
        </Header>
    );
}

export default Home;