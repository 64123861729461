import React from 'react';
import Header from './Header';
import {makeStyles, Typography} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: 'FenwickWoodtype',
        marginBottom: '30px'
    },
    body: {
        lineHeight: '1.75',
        margin: '30px',
        fontFamily: 'FenwickWoodtype'
    },
}));

function Reception(props) {
    const classes = useStyles();

    return (
        <Header setDisplayPath={props.setDisplayPath}>
            <Typography variant='h2' align='center' className={classes.title}>Reception</Typography>
            <Typography variant='h4' paragraph={true} className={classes.body}>
                &emsp;We wish we could celebrate with you right away, but due to the pandemic we are planning on postponing our reception. Will will be keeping an eye on the state of the pandemic and will host the event when things seem safe.
            </Typography>
            <Typography variant='h4' paragraph={true} className={classes.body}>
                &emsp;We will update this page with the date and time once they are determined. We look forward to celebrating with you then!
            </Typography>
        </Header>
    );
}

export default Reception;